<template>
  <section
    id="contact"
  >
    <v-img
      :src="require('@/assets/background/5.png')"
      :max-height="maxHeight"
    >
      <base-section-heading
        title="Contact Us"
        class="mt-12"
      >
        <!-- Get the PRO version of <strong>Zero</strong> and level up your theme<br>with additional components, pages, and Free updates. -->
      </base-section-heading>

      <v-img
        :src="require('@/assets/p3-logo-transparent.png')"
        class="mx-auto mb-8"
        max-width="128"
      />

      <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            md="4"
          >
            <a
              :href="feature.link"
              target="_blank"
            >
              <v-row
                justify="center"
                align="center"
              >
                <v-icon
                  size="100"
                  color="secondary"
                >
                  {{ feature.icon }}
                </v-icon>
              </v-row>
              <v-row
                justify="center"
                align="center"
                class="mb-6"
              >
                <span
                  class="text-h5 upper-case"
                >
                  {{ feature.title }}
                </span>
              </v-row>
            </a>
          </v-col>
        </v-row>
      </v-container>

      <!-- <v-container>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="7"
          >
            <v-simple-table>
              <thead>
                <tr>
                  <th />
                  <th class="text-body-1 font-weight-bold text-center">
                    What's in the Demo?
                  </th>
                  <th class="text-body-1 font-weight-bold text-center">
                    What's in the PRO version?
                  </th>
                </tr>
              </thead>

              <tbody class="text-body-1">
                <tr
                  v-for="([name, one, two], i) in features"
                  :key="i"
                >
                  <td v-text="name" />

                  <td class="text-center font-weight-bold">
                    <template v-if="typeof one === 'boolean'">
                      <v-icon :color="one ? 'success' : 'error'">
                        mdi-{{ one ? 'check' : 'close' }}
                      </v-icon>
                    </template>

                    <template v-else>
                      {{ one }}
                    </template>
                  </td>

                  <td class="text-center font-weight-bold">
                    <template v-if="typeof two === 'boolean'">
                      <v-icon :color="two ? 'success' : 'error'">
                        mdi-{{ two ? 'check' : 'close' }}
                      </v-icon>
                    </template>

                    <template v-else>
                      {{ two }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <v-col
            cols="12"
            md="5"
          >
            <v-card
              elevation="16"
              class="mb-12"
            >
              <base-img
                max-width="100%"
                src="https://cdn.vuetifyjs.com/store/themes/zero/pro.png"
              />
            </v-card>

            <div class="text-center">
              <base-btn
                :tile="false"
                color="primary"
                href="https://store.vuetifyjs.com/products/zero-theme-pro/?ref=vtyd-pro-page-features"
                rounded
              >
                Buy Pro Now

                <v-icon right>
                  mdi-rocket
                </v-icon>
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </v-container> -->
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionContactUs',

    data: () => ({
      // features: [
      //   ['Components', 5, '40+'],
      //   ['Example Pages', 3, '10+'],
      //   ['Vue CLI Support', true, true],
      //   ['Bugfixes and Issues', false, true],
      //   ['6 Months Free Updates', false, true],
      //   ['Supports Vuetify', false, true],
      //   ['Price', 'Free', '$59'],
      // ],
      features: [
        {
          title: 'Email Us',
          icon: 'mdi-email',
          link: 'mailto:support@positionplusplus.com',
        },
        {
          title: 'Visit Us on YouTube',
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          title: 'Visit Us on GitHub',
          icon: 'mdi-github',
          link: 'https://github.com/sfull/p3Bernese_Public',
        },
      ],
    }),

    computed: {
      maxHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '500' : '800'
      },
    },
  }
</script>

<style>
  a:link {
    text-decoration: none;
    color: black;
  }

  a:visited {
    text-decoration: none;
    color: black;
  }
</style>
